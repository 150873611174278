/* You can add global styles to this file, and also import other style files */
body { margin: 0;
  font-family: "Inter", sans-serif;
  font-optical-sizing: auto;
  font-weight: 200;
  font-style: normal;
  font-variation-settings:
    "slnt" 0;
}

.mat-datepicker-toggle .mat-icon-button {
  color: #d8d8d8 !important;
  border: 3px solid #d8d8d8;
  transform: scale(0.7);
}


.mat-datepicker-toggle:active .mat-icon-button:active {
  color: #E55933 !important;
  border: 3px solid #E55933;
  transform: scale(0.7);
}

.mat-form-field{
  font-family: "Inter", sans-serif !important;
}

.menuItem{
  cursor: pointer !important;
}


/* Hide scrollbar for Chrome, Safari and Opera */
*::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
* {
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}


.mat-dialog-container {
  border-radius: 20px !important;
}


.mat-form-field-underline{
  visibility: hidden !important;
}


.mat-form-field-appearance-fill .mat-form-field-flex {
  background-color: transparent !important;
}

.mat-form-field-wrapper {
  padding-bottom: 0px !important;
}

.mat-form-field-appearance-fill .mat-form-field-flex {
  margin-left: 10px;
  margin-top: 5px;
  padding: 0px !important;
}

.wrapper{
  max-width: 450px;
  width: 100%;
  margin: 100px auto 0;
  background: #fff;
  border-radius: 3px;
  padding: 50px;
}

.checkbox_item .title{
  padding-bottom: 15px;
  border-bottom: 1px solid #e5e9ec;
  font-size: 20px;
  font-weight: bold;
  letter-spacing: 3px;
  margin-bottom: 25px;
  text-align: center;
}

.checkbox_item .checkbox_wrap{
  position: relative;
  display: block;
  cursor: pointer;
  width: 30px; /* Ridotta proporzionalmente */
}

.checkbox_item:last-child .checkbox_wrap{
  margin-bottom: 0;
}

.checkbox_item .checkbox_wrap .checkbox_inp{
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
  z-index: 1;
}

.checkbox_item .checkbox_wrap .checkbox_mark{
  display: inline-block;
  position: relative;
  border-radius: 7.5px; /* Ridotta proporzionalmente */
}

.checkbox_item .checkbox_wrap .checkbox_mark:before,
.checkbox_item .checkbox_wrap .checkbox_mark:after{
  content: "";
  position: absolute;
  transition: all 0.5s ease;
}


/* basic styles */
.checkbox_item.citem_1 .checkbox_wrap .checkbox_mark{
  background: #FF0000B3;
  width: 30px; /* Ridotta proporzionalmente */
  height: 15px; /* Ridotta proporzionalmente */
  padding: 0.6px; /* Ridotta proporzionalmente */
}

.checkbox_item.citem_1 .checkbox_wrap .checkbox_mark:before{
  top: 0.9px; /* Ridotta proporzionalmente */
  left: 0.9px; /* Ridotta proporzionalmente */
  width: 13.2px; /* Ridotta proporzionalmente */
  height: 13.2px; /* Ridotta proporzionalmente */
  background: #fff;
  border-radius: 50%;
}

.checkbox_item.citem_1 .checkbox_wrap .checkbox_inp:checked ~ .checkbox_mark{
  background: #34bfa3;
}

.checkbox_item.citem_1 .checkbox_wrap .checkbox_inp:checked ~ .checkbox_mark:before{
  left: 16.2px; /* Ridotta proporzionalmente */
}

/* flat styles */
.checkbox_item.citem_2 .checkbox_wrap .checkbox_mark{
  border: 1.5px solid #f0f0f0; /* Ridotta proporzionalmente */
  width: 30px; /* Ridotta proporzionalmente */
  height: 15px; /* Ridotta proporzionalmente */
  padding: 0.6px; /* Ridotta proporzionalmente */
}

.checkbox_item.citem_2 .checkbox_wrap .checkbox_mark:before{
  top: 0.6px; /* Ridotta proporzionalmente */
  left: 0.6px; /* Ridotta proporzionalmente */
  width: 13.2px; /* Ridotta proporzionalmente */
  height: 10.8px; /* Ridotta proporzionalmente */
  background: #f0f0f0;
  border-radius: 7.5px; /* Ridotta proporzionalmente */
}

.checkbox_item.citem_2 .checkbox_wrap .checkbox_inp:checked ~ .checkbox_mark{
  border-color: #34bfa3;
}

.checkbox_item.citem_2 .checkbox_wrap .checkbox_inp:checked ~ .checkbox_mark:before{
  left: 13.2px; /* Ridotta proporzionalmente */
  background: #34bfa3;
}

/* skewed styles */
.checkbox_item.citem_3 .checkbox_wrap .checkbox_mark{
  width: 30px; /* Ridotta proporzionalmente */
  height: 15px; /* Ridotta proporzionalmente */
  padding: 0.6px; /* Ridotta proporzionalmente */
  border-radius: 0;
  transform: skew(-10deg);
  overflow: hidden;
}

.checkbox_item.citem_3 .checkbox_wrap .checkbox_mark:before,
.checkbox_item.citem_3 .checkbox_wrap .checkbox_mark:after{
  top: 0;
  left: 0;
  width: 100%;
  height: 15px; /* Ridotta proporzionalmente */
  text-align: center;
  line-height: 15px; /* Ridotta proporzionalmente */
  font-size: 7.5px; /* Ridotta proporzionalmente */
  color: #fff;
  font-weight: bold;
}

.checkbox_item.citem_3 .checkbox_wrap .checkbox_mark:before{
  content: "NO";
  background: #f0f0f0;
}

.checkbox_item.citem_3 .checkbox_wrap .checkbox_mark:after{
  content: "YES";
  left: -30px; /* Ridotta proporzionalmente */
  background: #34bfa3;
}

.checkbox_item.citem_3 .checkbox_wrap .checkbox_inp:checked ~ .checkbox_mark:after{
  left: 0;
}

.checkbox_item.citem_3 .checkbox_wrap .checkbox_inp:checked ~ .checkbox_mark:before{
  left: 30px; /* Ridotta proporzionalmente */
}

.checkbox-wrapper-26 * {
  -webkit-tap-highlight-color: transparent;
  outline: none;
}

.checkbox-wrapper-26 input[type="checkbox"] {
  display: none;
}

.checkbox-wrapper-26 label {
  --size: 20px;

  position: relative;
  display: block;
  width: var(--size);
  height: var(--size);
  margin: 0 auto;
  background-color: #FF0000B3;
  border-radius: 50%;
  cursor: pointer;
  transition: 0.2s ease transform, 0.2s ease background-color,
  0.2s ease box-shadow;
  overflow: hidden;
  z-index: 1;
}

.checkbox-wrapper-26 label:before {
  content: "";
  position: absolute;
  top: 50%;
  right: 0;
  left: 0;
  width: calc(var(--size) * .7);
  height: calc(var(--size) * .7);
  margin: 0 auto;
  background-color: #fff;
  transform: translateY(-50%);
  border-radius: 50%;
  transition: 0.2s ease width, 0.2s ease height;
}

.checkbox-wrapper-26 label:hover:before {
  width: calc(var(--size) * .55);
  height: calc(var(--size) * .55);
}

.checkbox-wrapper-26 label:active {
  transform: scale(0.9);
}

.checkbox-wrapper-26 .tick_mark {
  position: absolute;
  top: -1px;
  right: 0;
  left: calc(var(--size) * -.05);
  width: calc(var(--size) * .6);
  height: calc(var(--size) * .6);
  margin: 0 auto;
  margin-left: calc(var(--size) * .14);
  transform: rotateZ(-40deg);
}

.checkbox-wrapper-26 .tick_mark:before,
.checkbox-wrapper-26 .tick_mark:after {
  content: "";
  position: absolute;
  background-color: #fff;
  border-radius: 2px;
  opacity: 0;
  transition: 0.2s ease transform, 0.2s ease opacity;
}

.checkbox-wrapper-26 .tick_mark:before {
  left: 0;
  bottom: 0;
  width: calc(var(--size) * .1);
  height: calc(var(--size) * .3);
  transform: translateY(calc(var(--size) * -.68));
}

.checkbox-wrapper-26 .tick_mark:after {
  left: 0;
  bottom: 0;
  width: 100%;
  height: calc(var(--size) * .1);
  transform: translateX(calc(var(--size) * .78));
}

.checkbox-wrapper-26 input[type="checkbox"]:checked + label {
  background-color: #34bfa3;
}

.checkbox-wrapper-26 input[type="checkbox"]:checked + label:before {
  width: 0;
  height: 0;
}

.checkbox-wrapper-26 input[type="checkbox"]:checked + label .tick_mark:before,
.checkbox-wrapper-26 input[type="checkbox"]:checked + label .tick_mark:after {
  transform: translate(0);
  opacity: 1;
}
.no-padding-dialog .mat-dialog-container {
  padding: 0 !important;
  border-radius: 20px !important;
  overflow: hidden !important;
}



.mat-select-panel-wrap {
  flex-basis: 100%;
  margin-left: 20px !important;
  margin-top: 60px !important;
}




/* Aggiungi queste regole al tuo file styles.css */

/* Regole responsive di base */
html, body {
  margin: 0;
  padding: 0;
  overflow-x: hidden;
  font-family: "Inter", sans-serif;
  box-sizing: border-box;
}

*, *:before, *:after {
  box-sizing: inherit;
}

/* Assicura che le immagini siano responsive */
img {
  max-width: 100%;
  height: auto;
}

/* Media queries globali */
@media screen and (max-width: 992px) {
  .container {
    padding: 0 15px;
  }

  /* Riduci il padding e i margini su schermi medi */
  .mat-card, .mat-form-field {
    padding: 12px !important;
    margin: 8px !important;
  }
}

@media screen and (max-width: 768px) {
  .container {
    padding: 0 10px;
  }

  /* Riduci ulteriormente il padding e i margini su schermi piccoli */
  .mat-card, .mat-form-field {
    padding: 8px !important;
    margin: 5px !important;
  }

  /* Nascondi elementi non essenziali */
  .hide-on-mobile {
    display: none !important;
  }

  /* Rendi i bottoni più grandi su dispositivi mobili per facilitare il tocco */
  button, .mat-button, .mat-raised-button {
    min-height: 44px !important;
    min-width: 44px !important;
  }
}

@media screen and (max-width: 480px) {
  /* Stile per dispositivi molto piccoli */
  .xs-full-width {
    width: 100% !important;
  }

  /* Stack verticale per elementi inline */
  .xs-stack {
    display: block !important;
    width: 100% !important;
    margin-bottom: 10px !important;
  }
}

/* Utility class per la responsività delle tabelle */
.responsive-table {
  width: 100%;
  overflow-x: auto;
}

/* Fix per i form su mobile */
.mat-form-field {
  width: 100%;
}

/* Fai sì che i dialoghi Material siano responsive */
.full-width-dialog .mat-dialog-container {
  width: 100vw;
  max-width: 100vw;
}

@media screen and (max-width: 768px) {
  .mat-dialog-container {
    padding: 15px !important;
    width: 90vw !important;
    max-width: 90vw !important;
    margin: 0 auto;
  }
}
